import React, {useState,useEffect} from 'react';
import coffees from '../assets/coffees.png'
import person_1 from '../assets/person_one.png'
import person_2 from '../assets/person_two.png'
import person_3 from '../assets/person_three.png'
import person_4 from '../assets/person_four.png'
import AOS from 'aos';
import CountUp from 'react-countup';
import { useInView } from 'react-intersection-observer';

function TheTeam() {
    useEffect(() => {
        AOS.init({
          duration : 2000
        });
      }, []);
      const [ref, inView] = useInView({
        threshold: 0.1      
      });
    
      const [animationKey, setAnimationKey] = useState(0);
     
      useEffect(() => {
        if (inView) {
            setAnimationKey(prevKey => prevKey + 1);
        }
    }, [inView]);
    return (
        <>
        <div className="w-screen relative top-[-100px] bg-[linear-gradient(180deg, #061818 0%, #000000 100%)] lg:mb-[50%] flex items-center justify-center">
            <div ref={ref} data-aos="fade-left" className='container_div relative flex items-center justify-center lg:mt-[-500px] lg:left-[-650px] left-[-185px]'>
                    <div data-aos="flip-up" className=" absolute lg:top-[350px] lg:left-[200px] box-border lg:w-[422px] w-[330px] left-[32px] top-[5px] h-[401px] border border-[rgba(75,241,245,0.8)] rounded-[49px]"></div>
                    <div data-aos="flip-down" className=" absolute lg:top-[365px] lg:left-[215px] box-border lg:w-[392px] w-[300px] left-[42px] top-[15px] h-[376px] border border-[rgba(250,0,0,0.8)] rounded-[49px]"></div>
                    <div data-aos="flip-up" className=" absolute lg:top-[390px] lg:left-[227px] box-border lg:w-[367px] w-[280px] left-[52px] top-[35px] h-[341px] border border-[rgba(75,241,245,0.8)] rounded-[49px]"></div>
                    <div data-aos="flip-down" className=" absolute lg:top-[410px] lg:left-[242px] box-border lg:w-[329px] w-[260px] left-[62px] top-[55px] h-[305px] border border-[rgba(250,0,0,0.8)] rounded-[49px]"></div>
                    <div data-aos="flip-up" className=" absolute lg:top-[430px] lg:left-[252px] box-border lg:w-[298px] w-[240px] left-[72px] top-[75px] h-[272px] border border-[rgba(75,241,245,0.8)] rounded-[49px]"></div>
                    <div data-aos="flip-down" className=" absolute lg:top-[450px] lg:left-[262px] box-border lg:w-[267px] w-[220px] left-[82px] top-[97px] h-[239px] border border-[rgba(250,0,0,0.8)] rounded-[49px]"></div>
                    <div data-aos="flip-up" className=" absolute lg:top-[470px] lg:left-[300px] box-border lg:w-[192px] w-[200px] left-[93px] top-[130px] h-[172px] border border-[rgba(75,241,245,0.8)] rounded-[49px]"></div>
                    <div data-aos="flip-right" className='absolute  lg:top-[490px] lg:left-[230px] top-[132px] left-[32px] w-[344px] h-[207px] text-center font-voyagerBold text-6xl font-bolder text-white leading-6'>
                        <span data-aos="zoom-out" id="lines_of_code" className='relative'>
                        <CountUp key={animationKey} start={0} end={200000} duration={10} />
                        </span>
                        +
                        <br />
                        <p className='relative mt-[50px]'>
                            LINES OF
                        </p>
                        <p className='relative mt-[50px]'>CODE</p>
                    </div>
                
                    <p data-aos="zoom-in" className='absolute lg:top-[370px]  lg:left-[650px] top-[450px] left-[30px] font-voyager text-5xl lg:text-7xl text-white leading-6'>LOST</p>
                    <img data-aos="zoom-in" src={coffees} className='absolute  lg:top-[350px] lg:left-[800px] top-[485px] left-[250px] lg:w-[248.43px] lg:h-[114.83px] w-[130px]' alt='Coffee Cups' />
                    <p data-aos="zoom-in" className='absolute lg:top-[440px]  lg:left-[650px] font-voyager lg:text-7xl text-5xl top-[450px] w-max left-[150px] text-white leading-6'>COUNT OF</p>
                    <p data-aos="zoom-in" className='absolute lg:top-[510px]  lg:left-[650px] font-voyager lg:text-7xl text-5xl top-[500px] left-[30px] text-white leading-6'>CAUFEESS!</p>
                    <div data-aos="zoom-in-down" className='absolute inline-flex lg:top-[590px] lg:left-[650px] lg:w-[200px] w-[350px] top-[550px] left-[5px]'>
                        <a target='_blank' rel="noreferrer" className='absolute ' href='https://www.linkedin.com/in/syedhamzahussaini/'><img src={person_1} className='md:w-[100%] w-[80%] relative ' alt='Avatar' /></a>
                        <a target='_blank' rel="noreferrer" className='absolute md:right-[-57px] right-[103px]' href='https://www.linkedin.com/in/rk-365247fs/'><img src={person_2} className='md:w-[100%]   w-[80%] relative ' alt='Avatar' /></a>
                        <a target='_blank' rel="noreferrer" className='absolute md:right-[-159px] right-[19px]' href='https://www.linkedin.com/in/maaz-hussaini/'><img src={person_3} className='md:w-[100%] w-[80%] relative ' alt='Avatar' /></a>
                        <a target='_blank' rel="noreferrer" className='absolute md:right-[-259px] right-[-70px]' href='https://www.linkedin.com/in/yusuf-hussaini'><img src={person_4} className='md:w-[100%] w-[80%] relative ' alt='Avatar' /></a>
                    </div>
                    <p data-aos="zoom-in-up" className='absolute lg:top-[700px] lg:left-[650px] top-[670px] left-[30px] inline-flex w-max  text-center font-voyager text-5xl lg:text-6xl font-bolder text-white leading-6'>
                        4 TECH NERDS
                    </p>
                
                <p className="top-[720px] left-[15px] w-[350px] text-2xl lg:w-[421.6px] lg:h-[60.8px] absolute lg:top-[532px] lg:left-[900px] rounded-[30.4px] font-normal font-voyager border-2 border-[rgba(75,241,245,1)] lg:transform lg:rotate-90 text-white lg:text-3xl text-center flex items-center justify-center">  <CountUp key={animationKey} start={0} end={15000} duration={20} />+ HOURS OF CODING</p>
            </div>
        </div>
</>
    );
};

export default TheTeam;