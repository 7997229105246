import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import Header from './components/Header';
import Intro from './components/Intro';
import Services from './components/Services';
import Whyus from './components/Whyus';
import TheTeam from './components/TheTeam';
import Footer from './components/Footer';
import Portfolio from './components/Portfolio';
import ContactUs from './components/ContactUs';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
 <>
 <div id="about"><Header /></div>
       <Intro />
    
    <div id="services">
      <Services/>
    </div>
      <Whyus/>
    <div id="team">
      <TheTeam/>
    </div>
    <div id="portfolio">
      <Portfolio/>
    </div>
    <div id="contact">

      <ContactUs/>
      </div>
    <Footer />
    
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
