import React, {useEffect} from 'react';
import '../styles/Whyus.css';
import whyus1 from "../assets/whyus-1.png"
import whyus2 from "../assets/whyus-2.png"
import whyus3 from "../assets/whyus-3.png"
import whyus4 from "../assets/whyus-4.png"
import whyus5 from "../assets/whyus-5.png"
import AOS from 'aos';

const Whyus = () => {
  useEffect(() => {
    AOS.init({
      duration : 2000
    });
  }, []);
  return (
    <>
    <div style={{zIndex: 2, background:'linear-gradient(rgb(0 0 0) 0%, rgb(0 0 0 / 0%) 100%)'}} className="w-screen relative flex flex-col items-center justify-center">
      <h2 data-aos="fade-up" className='whyus-title'>Why Us</h2>
      <div className="flex flex-wrap">
        <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/3 xl:w-1/3 flex justify-center">
          {/* Div 1 with image */}
          <div  data-aos="fade-right" className="whyus-div p-4 m-2 text-center">
            <img src={whyus1} alt="1" className="mx-auto" />
            <h3>GREAT VALUE PROPOSITION</h3>
            <p>We believe in delivering value, regardless of you being our customer.
              We will give you the right advise and point you in the right direction, always!</p>
          </div>
        </div>
        <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/3 xl:w-1/3 flex justify-center">
          {/* Div 1 with image */}
          <div data-aos="fade-down"  className="whyus-div p-4 m-2 text-center">
            <img src={whyus2} alt="2" className="mx-auto" />
            <h3>SEAMLESS COLLABORATIONS</h3>
            <p>Our effective communication process builds soild grounds for an effective and seamless collaboration.</p>
          </div>
        </div>
        <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/3 xl:w-1/3 flex justify-center">
          {/* Div 1 with image */}
          <div data-aos="fade-left"  className="whyus-div p-4 m-2 text-center">
            <img src={whyus3} alt="3" className="mx-auto" />
            <h3>COMPETITIVE PRICING</h3>
            <p>Got a quote from our competitors? We will beat it! Ask and you shall receive!</p>
          </div>
        </div>
        <div className="w-full sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2 flex justify-center">
          {/* Div 4 with image */}
          <div data-aos="fade-right" className="whyus-div p-4 m-2 text-center">
            <img src={whyus4} alt="4" className="mx-auto" />
            <h3>UN-COMPROMISED QUALITY</h3>
            <p>We do not play around with quality. You can rely on us for a great working product through all phases of the development cycle</p>
          </div>
        </div>
        <div className="w-full sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2 flex justify-center">
          {/* Div 4 with image */}
          <div data-aos="fade-left" className="whyus-div p-4 m-2 text-center">
            <img src={whyus5} alt="4" className="mx-auto" />
            <h3>UP TO DATE TECHNOLOGY</h3>
            <p>We are obsessed with using the best technology out there. With a rapidly evolving technological landscape, we love to explore newer and better avenues for great product development</p>
          </div>
        </div>
      </div>
      <div  className="m-scroll">
        <div className="m-scroll__title">
          <div>
            <h1>
              <div className='scroll'>Innovating</div><div className='scroll'>&nbsp;Experiences .</div>&nbsp;
              <div className='scroll'>Innovating</div><div className='scroll'>&nbsp;Experiences .</div>&nbsp;
              <div className='scroll'>Innovating</div><div className='scroll'>&nbsp;Experiences .</div>&nbsp;
              <div className='scroll'>Innovating</div><div className='scroll'>&nbsp;Experiences .</div>&nbsp;
              <div className='scroll'>Innovating</div><div className='scroll'>&nbsp;Experiences .</div>&nbsp;
              <div className='scroll'>Innovating</div><div className='scroll'>&nbsp;Experiences .</div>&nbsp;
              <div className='scroll'>Innovating</div><div className='scroll'>&nbsp;Experiences .</div>&nbsp;
              <div className='scroll'>Innovating</div><div className='scroll'>&nbsp;Experiences .</div>&nbsp;
              <div className='scroll'>Innovating</div><div className='scroll'>&nbsp;Experiences .</div>&nbsp;
              <div className='scroll'>Innovating</div><div className='scroll'>&nbsp;Experiences .</div>&nbsp;
            </h1>
          </div>
        </div>
      </div>
    </div>
    <div data-aos="fade-left" className='relative lg:left-[120px] left-[50px]'>
      <div className="relative w-[340px] h-[300px] bg-[#4BF1F5] blur-[200px] rounded-full"></div>
            <p data-aos="fade-down" className="relative font-voyager text-5xl text-white top-[-180px] left-[50px]">
                OUR TEAM
            </p>
            </div>
</>
  );
};

export default Whyus;
