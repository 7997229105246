import React from 'react';
import logo from '../assets/byte_fuel_logo_transparent.png'

function Footer() {

    return (
        <footer className="absolute w-screen bg-black">
            <div className="flex justify-between items-center px-10">
                <div>
                    <img src={logo} alt="Logo" style={{ width: '128px', height: '95px' }} />
                </div>
                <div>
                    <div className="flex justify-center items-center space-x-4">
                        <a href="https://www.upwork.com/agencies/1690823924582146048/" target='_blank' rel="noreferrer"><svg width="25" height="18" viewBox="0 0 25 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M19.3321 10.2286C18.1819 10.2286 17.1054 9.73434 16.1259 8.92947L16.3643 7.79388L16.3748 7.75239C16.5876 6.5433 17.2609 4.51274 19.3321 4.51274C20.8855 4.51274 22.1491 5.79651 22.1491 7.37307C22.1444 8.94488 20.8809 10.2286 19.3321 10.2286ZM19.3321 1.61449C16.6858 1.61449 14.6344 3.36055 13.801 6.23154C12.5269 4.29108 11.5637 1.9618 10.9992 0H8.15179V7.52479C8.15179 9.0077 6.9607 10.2168 5.49961 10.2168C4.03851 10.2168 2.84859 9.0077 2.84859 7.52479V0H3.34659e-05V7.52479C-0.0104864 10.6068 2.46052 13.1364 5.49493 13.1364C8.52934 13.1364 11.0003 10.6068 11.0003 7.52479V6.26236C11.5544 7.4347 12.2323 8.61771 13.0564 9.67033L11.3113 18H14.2276L15.4912 11.9581C16.5993 12.6788 17.8734 13.1305 19.3344 13.1305C22.4577 13.1305 25 10.5321 25 7.36121C24.9977 4.19625 22.4554 1.61449 19.3321 1.61449Z" fill="white" fillOpacity="0.4" />
                            <path d="M15.5387 8.80617L15.4639 9.16213L15.745 9.39304C16.7947 10.2556 18.0016 10.8286 19.3321 10.8286C21.2213 10.8286 22.7435 9.26754 22.7491 7.37485V7.37307C22.7491 5.47391 21.2256 3.91274 19.3321 3.91274C18.0598 3.91274 17.2039 4.55279 16.6624 5.33878C16.1439 6.09148 15.9023 6.98681 15.7879 7.62607L15.7827 7.64642L15.7797 7.65844L15.7771 7.67058L15.5387 8.80617ZM13.2994 6.56086L14.0142 7.64942L14.3772 6.39881C15.1518 3.73042 17.0048 2.21449 19.3321 2.21449C22.1157 2.21449 24.3979 4.51935 24.4 7.36166C24.3998 10.2119 22.1153 12.5305 19.3344 12.5305C18.0048 12.5305 16.8427 12.1214 15.8183 11.4551L15.0834 10.9771L14.9039 11.8353L13.7401 17.4H12.05L13.6437 9.79337L13.7009 9.52022L13.5288 9.30047C12.7395 8.29223 12.0836 7.15027 11.5428 6.00599L10.4003 6.26236V7.52479C10.4003 10.287 8.18646 12.5364 5.49493 12.5364C2.80304 12.5364 0.590609 10.2871 0.600034 7.52684V7.52479V0.6H2.24859V7.52479C2.24859 9.33025 3.69838 10.8168 5.49961 10.8168C7.30049 10.8168 8.75179 9.3306 8.75179 7.52479V0.6H10.5521C11.142 2.51031 12.079 4.70208 13.2994 6.56086Z" stroke="white" strokeOpacity="0.4" strokeWidth="1.2" />
                        </svg> </a>
                        <a href="https://www.instagram.com/byte.fuel/?utm_source=ig_web_button_share_sheet&igshid=OTQ5NTI0NzZiOA==" target='_blank' rel="noreferrer"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M17.5 6.5H17.51M7 2H17C19.7614 2 22 4.23858 22 7V17C22 19.7614 19.7614 22 17 22H7C4.23858 22 2 19.7614 2 17V7C2 4.23858 4.23858 2 7 2ZM16 11.37C16.1234 12.2022 15.9813 13.0522 15.5938 13.799C15.2063 14.5458 14.5931 15.1514 13.8416 15.5297C13.0901 15.9079 12.2384 16.0396 11.4078 15.9059C10.5771 15.7723 9.80976 15.3801 9.21484 14.7852C8.61992 14.1902 8.22773 13.4229 8.09407 12.5922C7.9604 11.7616 8.09207 10.9099 8.47033 10.1584C8.84859 9.40685 9.45419 8.79374 10.201 8.40624C10.9478 8.01874 11.7978 7.87659 12.63 8C13.4789 8.12588 14.2649 8.52146 14.8717 9.12831C15.4785 9.73515 15.8741 10.5211 16 11.37Z" stroke="white" strokeOpacity="0.4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        </svg></a>
                        <a href="https://www.linkedin.com/company/byte-fuel/" target='_blank' rel="noreferrer"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M16 8C17.5913 8 19.1174 8.63214 20.2426 9.75736C21.3679 10.8826 22 12.4087 22 14V21H18V14C18 13.4696 17.7893 12.9609 17.4142 12.5858C17.0391 12.2107 16.5304 12 16 12C15.4696 12 14.9609 12.2107 14.5858 12.5858C14.2107 12.9609 14 13.4696 14 14V21H10V14C10 12.4087 10.6321 10.8826 11.7574 9.75736C12.8826 8.63214 14.4087 8 16 8Z" stroke="white" strokeOpacity="0.4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M6 9H2V21H6V9Z" stroke="white" strokeOpacity="0.4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M4 6C5.10457 6 6 5.10457 6 4C6 2.89543 5.10457 2 4 2C2.89543 2 2 2.89543 2 4C2 5.10457 2.89543 6 4 6Z" stroke="white" strokeOpacity="0.4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        </svg></a>
                    </div>
                </div>
            </div>
            <div className="text-center text-base pb-2" style={{ color: 'rgba(255, 255, 255, 0.63)' }}>
                © 2023 ByteFuel. All rights reserved.
            </div>
        </footer>
    );
}

export default Footer;