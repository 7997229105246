import React, { useState, useEffect } from "react";
import AOS from "aos";
import { CSSTransition } from "react-transition-group";
import "../styles/Services.css";
import IframeModal from "./IframeModal";

function Services() {
  const [modalOpen, setModalOpen] = useState(false);

  const openModal = () => {
    document.body.style.overflow = "hidden";
    setModalOpen(true);
  };

  const closeModal = () => {
    document.body.style.overflow = "auto";
    setModalOpen(false);
  };
  const dropdowns = [
    {
      text: "THE WEBSITE",
      content: `
      <div class="flex items-start"><span class="font-bold pr-2">•</span><span>Completely Personalized and Custom design</span></div>
      <div class="flex items-start"><span class="font-bold pr-2">•</span><span>SEO friendly</span></div>
      <div class="flex items-start"><span class="font-bold pr-2">•</span><span>2-3 Revisions in Design</span></div>
      <div class="flex items-start"><span class="font-bold pr-2">•</span><span>Quality Assurance</span></div>
      <div class="flex items-start"><span class="font-bold pr-2">•</span><span>Data Base Construction</span></div>
      <div class="flex items-start"><span class="font-bold pr-2">•</span><span>Hosting and Domain of your choice</span></div>
      <div class="flex items-start"><span class="font-bold pr-2">•</span><span>And More</span></div>      
      `,

      callToAction: "Lets HOP ON A FREE CALL NOW!",
    },
    {
      text: "APPLICATION DEVELOPMENT",
      content: `
      <div class="flex items-start"><span class="font-bold pr-2">•</span><span>System Architecture and Design</span></div>
      <div class="flex items-start"><span class="font-bold pr-2">•</span> <span>Front-End and Back-End Development</span> </div>
      <div class="flex items-start"><span class="font-bold pr-2">•</span> <span>API Integrations</span> </div>
      <div class="flex items-start"><span class="font-bold pr-2">•</span> <span>Testing and Quality Assurance</span> </div>
      <div class="flex items-start"><span class="font-bold pr-2">•</span> <span>Launch and Deployment, and post deployment support!</span> </div>
      <div class="flex items-start"><span class="font-bold pr-2">•</span> <span>SUPERFAST DELIVERY</span> </div>
      <div class="flex items-start"><span class="font-bold pr-2">•</span> <span>And More!</span> </div>
      `,

      callToAction: "Lets HOP ON A FREE CALL NOW!",
    },
    {
      text: "MOBILE DEVELOPMENT",
      content: `
      <div class="flex items-start"><span class="font-bold pr-2">•</span><span>The Project Discovery</span></div>
      <div class="flex items-start"><span class="font-bold pr-2">•</span><span>UI/UX Design</span></div>
      <div class="flex items-start"><span class="font-bold pr-2">•</span><span>Frontend and Backend development</span></div>
      <div class="flex items-start"><span class="font-bold pr-2">•</span><span>Payment Gateways & Admin Dashboards</span></div>
      <div class="flex items-start"><span class="font-bold pr-2">•</span><span>AI integrations</span></div>
      <div class="flex items-start"><span class="font-bold pr-2">•</span><span>Chatbot integrations</span></div>
      <div class="flex items-start"><span class="font-bold pr-2">•</span><span>And More</span></div>
      `,

      callToAction: "Lets HOP ON A FREE CALL NOW!",
    },
    {
      text: "SEO & SMM BOOSTER",
      content: `
      <div class="flex items-start"><span class="font-bold pr-2">•</span><span>Personalized Marketing Strategy</span></div>
      <div class="flex items-start"><span class="font-bold pr-2">•</span><span>Google Analytics</span></div>
      <div class="flex items-start"><span class="font-bold pr-2">•</span><span>Content Strategy</span></div>
      <div class="flex items-start"><span class="font-bold pr-2">•</span><span>High Converting Blog Posts</span></div>
      <div class="flex items-start"><span class="font-bold pr-2">•</span><span>Live dashboard</span></div>
      <div class="flex items-start"><span class="font-bold pr-2">•</span><span>Email & Social Media Management, posts tailored for you</span></div>
      <div class="flex items-start"><span class="font-bold pr-2">•</span><span>And More</span></div>
      `,

      callToAction: "Lets HOP ON A FREE CALL NOW!",
    },
    {
      text: "AI AUTOMATION",
      content: `
      <div class="flex items-start"><span class="font-bold pr-2">•</span><span>Automation Bots for your Business needs</span></div>
      <div class="flex items-start"><span class="font-bold pr-2">•</span><span>Full setup and integration</span></div>
      <div class="flex items-start"><span class="font-bold pr-2">•</span><span>Launch, Deployment, and Support</span></div>
      <div class="flex items-start"><span class="font-bold pr-2">•</span><span>And More</span></div>
      `,

      callToAction: "Lets HOP ON A FREE CALL NOW!",
    },
    {
      text: "WORKFLOW AUTOMATION",
      content: `
      <div class="flex items-start"><span class="font-bold pr-2">•</span><span>Creating Workflow Automations has never been easier</span></div>
      <div class="flex items-start"><span class="font-bold pr-2">•</span><span>Automations for Cross functional teams</span></div>
      <div class="flex items-start"><span class="font-bold pr-2">•</span><span>Automation for you, and your team</span></div>
      <div class="flex items-start"><span class="font-bold pr-2">•</span><span>And More</span></div>
      `,

      callToAction: "Lets HOP ON A FREE CALL NOW!",
    },
  ];
  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
  }, []);
  // Split the dropdowns into two columns
  const columnOneDropdowns = dropdowns.slice(0, 3);
  const columnTwoDropdowns = dropdowns.slice(3, 6);
  const [openDropdownIndex, setOpenDropdownIndex] = useState(null);
  const aosAnimations = ["fade-up"];
  const handleToggleDropdown = (index) => {
    if (openDropdownIndex === index) {
      // If the same dropdown is clicked, close it
      setOpenDropdownIndex(null);
    } else {
      // Open the clicked dropdown and close others
      setOpenDropdownIndex(index);
    }
  };
  return (
    <>
      <div
        style={{
          zIndex: "2",
          background: "linear-gradient(rgb(0, 0, 0) 0%, rgb(0, 0, 0) 100%)",
        }}
        className="relative lg:pt-[50px] top-0 flex flex-col lg:flex-row justify-center items-center gap-4"
      >
        {/* First Column */}
        <div className="flex flex-col items-center justify-center lg:mr-8">
          {columnOneDropdowns.map((dropdown, index) => (
            <Dropdown
              text={dropdown.text}
              key={index}
              animation={aosAnimations[index % aosAnimations.length]}
              isOpen={openDropdownIndex === index}
              onToggle={() => handleToggleDropdown(index)}
            >
              <div className="relative p-2 lg:w-[427px] w-[300px]">
                <div
                  className="text-[12px] lg:text-[16px] font-[400] leading-[25px] tracking-[0em] text-left font-['Manrope'] text-[#d9d9d9]"
                  dangerouslySetInnerHTML={{ __html: dropdown.content }}
                ></div>
              </div>
              <button
                onClick={openModal}
                className="flex p-2 relative justify-center items-center lg:w-[337px] w-[230px] lg:h-[63px] h-[50px] rounded-[31.5px] bg-[#d9d9d978] shadow-xl m-5 mx-8"
                style={{ boxShadow: "4px 4px 50px rgba(255, 255, 255, 0.3)" }}
              >
                <p className="lg:text-base text-xs font-['Manrope'] text-[#d9d9d9] leading-[21.86px] font-[600]">
                  {dropdown.callToAction}
                </p>
              </button>
            </Dropdown>
          ))}
        </div>

        {/* Second Column */}
        <div className="flex flex-col items-center justify-center ">
          {columnTwoDropdowns.map((dropdown, index) => (
            <Dropdown
              text={dropdown.text}
              key={index + 3}
              animation={aosAnimations[(index + 3) % aosAnimations.length]}
              isOpen={openDropdownIndex === index + 3}
              onToggle={() => handleToggleDropdown(index + 3)}
            >
              <div className="relative p-2 lg:w-[427px] p-2 w-[300px]">
                <div
                  className="text-[12px] lg:text-[16px] font-[400] leading-[25px] tracking-[0em] text-left font-['Manrope'] text-[#d9d9d9]"
                  dangerouslySetInnerHTML={{ __html: dropdown.content }}
                ></div>
              </div>
              <button
                onClick={openModal}
                className="flex p-2 relative justify-center items-center lg:w-[337px] w-[230px] lg:h-[63px] h-[50px] rounded-[31.5px] bg-[#d9d9d978] shadow-xl m-5 mx-8"
                style={{ boxShadow: "4px 4px 50px rgba(255, 255, 255, 0.3)" }}
              >
                <p className="lg:text-base text-xs font-['Manrope'] text-[#d9d9d9] leading-[21.86px] font-[600]">
                  {dropdown.callToAction}
                </p>
              </button>
            </Dropdown>
          ))}
        </div>
      </div>
      <IframeModal isOpen={modalOpen} onRequestClose={closeModal} />
    </>
  );
}
const Dropdown = ({
  text,
  children,
  isOpen: isOpenProp,
  onToggle,
  animation,
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  useEffect(() => {
    setIsDropdownOpen(isOpenProp);
  }, [isOpenProp]);
  return (
    <>
      <div
        style={{ zIndex: "2" }}
        data-aos={animation}
        className="m-2 relative lg:w-[427px] w-[300px] border rounded-[41.5px] border-[#d9d9d961]"
      >
        <button
          onClick={onToggle}
          className="text-white rounded-full font-voyager  font-normal leading-6 w-full flex justify-between text-center items-center h-[50px] text-[14px] p-4 lg:h-[83px] lg:text-[24px]"
        >
          {text}
          <span className="lg:ml-4 relative pl-[20px]">
            {" "}
            {isDropdownOpen ? (
              <i className="fa fa-chevron-up"></i>
            ) : (
              <i className="fa fa-chevron-down"></i>
            )}
          </span>
        </button>
        <CSSTransition
          in={isDropdownOpen}
          timeout={200}
          classNames="dropdown"
          unmountOnExit
        >
          <div className="m-2 block">{children}</div>
        </CSSTransition>
      </div>
    </>
  );
};
export default Services;
