
import React,{useState,useEffect} from 'react';
import Calendar from 'react-calendar';
import '../styles/ContactUs.css';
import IframeModal from './IframeModal';
import AOS from 'aos';

const ContactUs = () => {
    const [date] = useState(new Date());
    const [modalOpen, setModalOpen] = useState(false);

const openModal = () => {
    document.body.style.overflow = 'hidden'; 
    setModalOpen(true);
};

const closeModal = () => {
    document.body.style.overflow = 'auto'; 
    setModalOpen(false);
};
useEffect(() => {
  AOS.init({
    duration : 2000 
  });
}, []);
 return (
  <>
    <div style={{zIndex: 2, background:'linear-gradient(#061818cc 0%, rgb(10 55 55) 100%)'}} className="p-8 lg:mt-[150px] mt-[-100px]">
      <div className="style_container grid grid-rows-2 md:grid-cols-2 place-items-center lg:h-[650px]" >
        <div data-aos='fade-right' className=" m-2 text-white text-center flex flex-col justify-center items-center">
        <p className=" flex relative flex-col font-voyager font-normal text-6xl leading-6 mb-4 text-white">
       LETS TALK
       </p>

       <p className="flex flex-col relative font-['Manrope'] mt-4 font-normal text-2xl leading-6 mb-4 text-white">
        contact@bytefuel.dev
       </p>
       <button className="rounded-[31.5px] relative w-64 h-16 mt-4 lg:text-[18px] font-[Manrope] rounded-full text-center bg-[#d9d9d978]"  onClick={openModal} style={{boxShadow: '4px 4px 50px rgba(255, 255, 255, 0.3)'}}>
           Let's Chat
         </button>
        </div>
        <div  style={{zIndex: 2}} data-aos='fade-left' className="m-2 text-white text-center flex justify-center items-center">
        <div className='relative calendar-container'>
        <p className='appointment_text absolute font-[Manrope] leading-6'>Book an appointment</p>
        <Calendar onChange={openModal} value={date} />
      </div>
        </div>
      </div>
    </div>
    <IframeModal isOpen={modalOpen} onRequestClose={closeModal} />
    </>
  );
};
export default ContactUs;