import React, { useState, useEffect, useMemo } from 'react';
import logo from '../assets/byte_fuel_logo_transparent.png';
import { Turn as Hamburger } from 'hamburger-react';
import blog from '../assets/blog.png';

function Header() {
    const [hasScrolled, setHasScrolled] = useState(false);
    const [activeLink, setActiveLink] = useState(null);
    const links = useMemo(() => ([
        { href: "#about", text: "About Us" },
        { href: "#services", text: "Our Services" },
        { href: "#team", text: "The Team" },
        { href: "#portfolio", text: "Portfolio" },
        { href: "#contact", text: "Contact" },
    ]), []);

    useEffect(() => {
        const handleScroll = () => {
            setHasScrolled(window.scrollY > 10);
        };
    
        window.addEventListener('scroll', handleScroll);
    
        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    setActiveLink(`#${entry.target.id}`);
                }
            });
        }, { threshold: 0.5 }); // Trigger when 50% of the element is visible in the viewport
    
        links.forEach(link => {
            const targetElement = document.querySelector(link.href);
            if (targetElement) {
                observer.observe(targetElement);
            }
        });
    
        return () => {
            window.removeEventListener('scroll', handleScroll);
            links.forEach(link => {
                const targetElement = document.querySelector(link.href);
                if (targetElement) {
                    observer.unobserve(targetElement);
                }
            });
        };
    }, [links]);
    
    const handleLinkClick = (e, href) => {
        e.preventDefault();
        // Scroll to top if the "About" section is clicked
        if (href === "#about") {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        } else {
            const targetElement = document.querySelector(href);
            if (targetElement) {
                const elementRect = targetElement.getBoundingClientRect();
                const absoluteElementTop = elementRect.top + window.pageYOffset;
                const middlePointOffset = window.innerHeight / 2; // Middle point of the viewport
                // Scroll to the 50% of the viewport for the element
                const scrollToPosition = absoluteElementTop - middlePointOffset;
                window.scrollTo({ top: scrollToPosition, behavior: 'smooth' });
            }
        }
    };

    const [isOpen, setOpen] = useState(false);
    
    return (
        <nav className={`animate__animated animate__fadeInDown fixed w-screen z-10 top-0 flex justify-between items-center ${hasScrolled ? 'backdrop-saturate-50 bg-black/90' : 'bg-transparent' }`}>
            <img src={logo} alt="Logo" style={{ width: '128px', height: '95px', paddingLeft: "20px" }} />
            <div className={`space-y-4 mr-10 ${isOpen ? 'flex absolute top-132 text-center w-full top-[95px] backdrop-saturate-10 bg-black/90 text-white' : 'text-white hidden flex-row '} lg:space-y-0 lg:space-x-8 lg:flex lg:flex-row flex-col`}>
                {links.map((link, index) => (
                    <a
                        key={index}
                        href={link.href}
                        onClick={e => handleLinkClick(e, link.href)}
                        className={`${activeLink === link.href ? 'backdrop-saturate-200 bg-white/30 p-1 rounded-md font-bold' : 'hover:text-opacity-60'}`}
                        style={{
                            fontFamily: 'Manrope',
                            fontSize: '13.5px',
                            lineHeight: '18.44px',
                        }}
                    >
                        {link.text}
                    </a>
                ))}
                <a href='https://thepantomath.com/' rel="noreferrer" target="_blank" className={`${isOpen ? 'flex justify-center pt-4 align-center' : ''} `}>
                    <img src={blog} alt="Logo" style={{ width: '70px', marginTop: '-20px' }} ></img>
                </a>
            </div>
            <div className="lg:hidden" style={{ color: 'white'}}>
                <Hamburger toggled={isOpen} toggle={setOpen} />
            </div>
        </nav>
    );
}

export default Header;
