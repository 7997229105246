import React from 'react';
import Modal from 'react-modal';
import '../styles/IframeModal.css'

Modal.setAppElement('#root');

function IframeModal({ isOpen, onRequestClose }) {
 
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Iframe Modal"
      style={{
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.4)',
          zIndex: 100
        },
        content: {
          position: 'absolute',
          inset: '50% auto auto 50%',
          border: '1px solid rgb(204, 204, 204)',
          overflow: 'hidden',
          transform: 'translate(-50%, -50%)',
          width: '80%',
          height: '80%',
          background: 'black',
          zIndex: '100'
        }
      }}
      className='animate__animated animate__fadeIn'    >
      <iframe 
        src="https://bytefuel.hbportal.co/public/64eb8c67ca6e55014076a7e1/1-Schedule"
        title="Book a Free Call"></iframe>

      <div style={{
        display: 'flex',
      }}>
        <p className="heading_text " style={{
          display: 'inline-flex',
          position: 'absolute',
          width: 'calc(100% - 0px)',
          background: 'white',
          top: '0px',
          left: '0px',
          textAlign: 'center',
          justifyContent: 'center',
          alignItems: 'center',
          fontFamily: 'cursive',
        }}> Book a free call</p>
        <button onClick={onRequestClose} className="close_button close-modal-button">
          <i className="fa fa-times" style={
            {
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
            }
          }></i>
        </button>
      </div>
    </Modal>

  );
}

export default IframeModal;
