import React, {useState, useEffect,useRef } from 'react';
import '../styles/Intro.css';
import IframeModal from './IframeModal';


const Intro = () => {
   const [modalOpen, setModalOpen] = useState(false);
   
   const [degreeLeft, setDegreeLeft] = useState(359);
   const [degreeRight, setDegreeRight] = useState(1);

  const openModal = () => {
      document.body.style.overflow = 'hidden'; 
      setModalOpen(true);
  };
  
  const closeModal = () => {
      document.body.style.overflow = 'auto'; 
      setModalOpen(false);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  //Window sizing for different backgrounds:
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const hasStarted = useRef(false);
 // Left Side Animation
  useEffect(() => {
      if (!hasStarted.current) {
          const timeoutId = setTimeout(() => {
              hasStarted.current = true;
              setDegreeLeft(degreeLeft - 1); // Start the animation
          }, 1000); // 1 second delay
  
          return () => {
              clearTimeout(timeoutId);
          };
      }
  }, [degreeLeft]);
  
  useEffect(() => {
      if (hasStarted.current) {
          const intervalId = setInterval(() => {
              if (degreeLeft > 90) {
                  setDegreeLeft(degreeLeft - 1);
              } else {
                  clearInterval(intervalId);
              }
          }, 20);

          return () => {
              clearInterval(intervalId);
          };
      }
  }, [degreeLeft]);

// Right side animation
  useEffect(() => {
    if (!hasStarted.current) {
        const timeoutId = setTimeout(() => {
          hasStarted.current = true;
            setDegreeRight(degreeRight + 1); // Start the animation for the right side
        }, 1000); // 1 second delay

        return () => {
            clearTimeout(timeoutId);
        };
    }
}, [degreeRight]);

useEffect(() => {
    if (hasStarted.current) {
        const intervalId = setInterval(() => {
            if (degreeRight < 270) {
                setDegreeRight(degreeRight + 1);
            } else {
                clearInterval(intervalId);
            }
        }, 20);

        return () => {
            clearInterval(intervalId);
        };
    }
}, [degreeRight]);

  const gradientStyleLeft = {
    background: windowWidth <= 500 ? `conic-gradient(from 93deg at 0% 50%, #f6f3f3 0deg, rgb(160 157 157) 13.12deg, #AAAAAA ${degreeLeft}deg, #f6f3f3 360deg)` : `conic-gradient(from 93deg at 50% 50%, #f6f3f3 0deg, rgb(160 157 157) 13.12deg, #AAAAAA ${degreeLeft}deg, #f6f3f3 360deg)`
  };

  const gradientStyleRight ={
    background: windowWidth <= 500 ? `conic-gradient(from 267deg at 100% 50%, #f6f3f3 0deg, #AAAAAA ${degreeRight}deg, rgb(160 157 157) 347deg, #f6f3f3 360deg)` : `conic-gradient(from 267deg at 50% 50%, #f6f3f3 0deg, #AAAAAA ${degreeRight}deg, rgb(160 157 157) 347deg, #f6f3f3 360deg)`
  };

  return (
    <>
    <div className="bytefuel w-screen relative flex flex-col items-center justify-center">
      <h1 className="animate__animated animate__fadeIn bytefuel-tag mb-4" style={{zIndex: '2'}}>
        Click . Code . Conquer
      </h1>
      <h2 className="animate__animated animate__fadeIn bytefuel-mission mb-4" style={{zIndex: '2'}}>
        Fueling your digital dreams, Byte by Byte
      </h2>
      <h3 className="animate__animated animate__fadeIn bytefuel-motto mb-4" style={{zIndex: '2'}}>A seamless design and development experience - we excel in
        <span> supercharging your brand.</span>
      </h3>
      <p className="animate__animated animate__fadeIn bytefuel-intro text-center px-4 leading-6" style={{zIndex: '2'}}>
      Our secret sauce? A blend of competitive pricing, unwavering commitment to quality, and an extra dash of brag-worthy expertise.</p>
       <br></br>
       <p className="animate__animated animate__fadeIn bytefuel-intro text-center px-4 leading-6" style={{zIndex: '2'}}>
       We are not just about flexing our skills. We're here to be your guiding light, your go-to source for insights and resources. Whether you're seeking advice, looking for inspiration, or simply need a nudge in the right direction, we've got your back. We believe in providing value!
      </p>
      <div className="button-container flex w-screen items-center justify-center" style={{zIndex: '2'}}> 
        <button className="animate__animated animate__fadeIn bytefuel-chat w-64 h-16 mt-4 bg-opacity-50 bg-gray-200 shadow-md rounded-full" onClick={openModal}>
          Let's Chat
        </button>
        <div className='animate__animated animate__fadeIn bytefuel-services-container'>
          <div className='bytefuel-services' /> 
          <div className='services-arrow'></div>
        </div>
      </div>
      <div className="group" >
            <div className="l" style={gradientStyleLeft} ></div>
            <div className="r" style={gradientStyleRight}></div>
        </div>
    </div>
    <IframeModal isOpen={modalOpen} onRequestClose={closeModal} />
    </>
  );
};

export default Intro;
