import React,{useEffect} from 'react';
import '../styles/Portfolio.css';
import cricket from "../assets/cricket.png"
import cocktail from "../assets/cocktail.png"
import petcare from "../assets/petcare.png"
import mithiyaj from "../assets/mithiyaj.png"
import AOS from 'aos';

const Portfolio = () => {
  useEffect(() => {
    AOS.init({
      duration : 2000 
    });
  }, []);

  return (
    <>
    <div style={{zIndex: 2 }} className="w-screen relative flex flex-col justify-center portfolio top-[-200px]">
      <h2 data-aos="fade-down" className='portfolio-title relative font-voyager text-5xl text-white lg:left-[160px] aos-init aos-animate'>OUR PORTFOLIO</h2>
      <div data-aos="fade-up" className="direction_down flex justify-center items-center mt-[50px] "> 
        <div className="p-4 images flex justify-center items-center"> 
          <img src={cricket} alt="World Cricket" />
        </div>
        <div className="p-4 images flex justify-center items-center">
          <img src={cocktail} alt="Cocktail Light" />
        </div>
        <div className="p-4 images flex justify-center items-center">
          <img src={petcare} alt="PetCare" />
        </div>
        <div className="p-4 images flex justify-center items-center">
          <img src={mithiyaj} alt="Mithiyaj" />
        </div>
      </div>
    </div>
    </>
  );
};

export default Portfolio;
